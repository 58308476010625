import { baseURL } from '@/api/requests/httpAxios'

export default class FakeUserAPI {
  public static fakeUserLogin(userId: number, roles: string[]) {
    const params = new URLSearchParams()
    params.append('userId', userId.toString())
    for (const role of roles) {
      params.append('roles', role)
    }
    window.location.href = `${baseURL}/auth/dev?${params.toString()}`
  }

  public static fakeUserLogout() {
    window.location.href = `${baseURL}/auth/dev/logout`
  }
}
